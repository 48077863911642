import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppRequestContextService } from '../../../app-request-context.service';

@Injectable({
  providedIn: 'root',
})
export class ProductExistsGuard {
  private appRequestContext = inject(AppRequestContextService);
  private router = inject(Router);

  async canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const { productId = '', cartItemId = '', itemId = '' } = routeSnapshot.params;

    const productIdRef = productId || cartItemId || itemId;
    const product = this.appRequestContext.products.find(p => p.id === productIdRef);

    if (!product || !product.active) {
      return this.getCatalogueUrlTree();
    }

    const category = this.appRequestContext.categories.find(c => c.id === product.categoryId);

    if (!category || !category.active) {
      return this.getCatalogueUrlTree();
    }
    // NOTE: Redirects to the new 'categoria/:categoryId/producto/:productId' route
    if (routeSnapshot.routeConfig.path === 'producto/:productId') {
      return this.router.createUrlTree([`/categoria/${category.id}/producto/${product.id}`]);
    }
    return true;
  }

  private getCatalogueUrlTree() {
    return this.router.createUrlTree(['/']);
  }
}

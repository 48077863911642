import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  inject,
  HostBinding,
} from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { isProductWithoutStock } from '@pedix-workspace/shared-models';
import { Product, ProductOptionItem } from '@pedix-workspace/utils';
import { ToastrService } from 'ngx-toastr';

import { Params, RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import {
  IconChevronRightComponent,
  IconClockComponent,
  IconCrossArrowsComponent,
  IconEditComponent,
  IconEyeSlashComponent,
  IconTrashCanComponent,
} from '@pedix-workspace/angular-ui-icons';
import { ImageComponent } from '@pedix-workspace/angular-ui-image';
import { CurrencyFormatPipe, PriceDiscountPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { ProductPriceComponent } from '../product-price/product-price.component';
import classnames from 'classnames';
import { ProductTagComponent } from '../product-tag/product-tag.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    RouterLink,
    IconEyeSlashComponent,
    IconClockComponent,
    IconEditComponent,
    IconTrashCanComponent,
    IconCrossArrowsComponent,
    IconChevronRightComponent,
    ImageComponent,
    CurrencyFormatPipe,
    ProductPriceComponent,
    ProductTagComponent,
  ],
})
export class ProductListItemComponent {
  private toastrService = inject(ToastrService);
  private t = inject(TranslocoService);
  private priceDiscountPipe = inject(PriceDiscountPipe);

  @Input() id: string;
  @Input() name: string;
  @Input() image: string;
  @Input() price: number;
  @Input() priceDiscount: number;
  @Input() sku?: string;
  @Input() hidden: boolean;
  @Input() stockQty: Product['stockQty'];
  @Input() tag: { tag: string; color: string };
  @Input() presentationItems?: ProductOptionItem[];
  @Input() isAvailable = true;
  @Input() isUpdatingImages?: boolean;
  @Input() listIndex: number;
  @Input() sortEnabled: boolean;
  @Input() isAdminMode: boolean;
  @Input() currencySymbol: string;
  @Input() detailRoute: string | string[] = [];
  @Input() detailRouteQueryParams: Params | null = null;
  @Input() displaySku = false;
  @Input() lazyLoadImageMinIndex = 9;

  @Output() clickEdit = new EventEmitter<Pick<Product, 'id'>>();
  @Output() clickDelete = new EventEmitter<Pick<Product, 'id' | 'name'>>();

  get entity() {
    return { id: this.id, name: this.name };
  }

  get withoutStock(): boolean {
    return isProductWithoutStock({
      stockQty: this.stockQty,
      presentationItems: this.presentationItems,
    });
  }

  get presentationMinimumPrice(): number {
    return (
      this.presentationItems?.reduce((minimumPrice, presentation) => {
        const currentPrice = presentation.priceDiscount || presentation.price;

        return currentPrice < minimumPrice ? currentPrice : minimumPrice;
      }, Number.MAX_VALUE) || 0
    );
  }

  get maxDiscount(): number {
    if (!this.presentationItems) {
      return 0;
    }
    return this.presentationItems.reduce((maxDiscount, presentation) => {
      const currentDiscount = this.priceDiscountPipe.transform(
        presentation.price,
        presentation.priceDiscount,
      );

      return currentDiscount > maxDiscount ? currentDiscount : maxDiscount;
    }, 0);
  }

  get hasDiscount(): boolean {
    return !!this.priceDiscount || !!this.maxDiscount;
  }

  @HostBinding('class')
  get classNames() {
    return classnames({
      'with-discount': this.hasDiscount,
      'with-sku': this.displaySku && this.sku,
      'admin-mode': this.isAdminMode,
      'product-inactive': this.withoutStock || this.hidden,
    });
  }

  onClickEdit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.isUpdatingImages) {
      this.toastrService.info(this.t.translate('product.waitingBackgroundTask'));

      return;
    }

    this.clickEdit.emit(this.entity);
  }

  onClickDelete($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.clickDelete.emit(this.entity);
  }
}

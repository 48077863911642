import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  booleanAttribute,
  inject,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import classnames from 'classnames';
import { IconInfoComponent } from '@pedix-workspace/angular-ui-icons';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';

let INPUT_UID = 0;

export type InputTextareaLabelSize = 'xs' | 'sm' | 'md';

export type InputTextareaValueType = string | undefined | null;

@Component({
  selector: 'pxw-input-textarea',
  standalone: true,
  templateUrl: './input-textarea.component.html',
  styleUrl: './input-textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputTextareaComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputTextareaComponent,
    },
  ],
  imports: [NgClass, IconInfoComponent],
})
export class InputTextareaComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input({ required: true }) name: string;
  @Input() label: string;
  @Input() labelSize: InputTextareaLabelSize = 'sm';
  @Input() helper: string;
  @Input() placeholder = '';
  @Input() autofocus: boolean;
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;
  @Input() rows?: number;

  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input({ transform: booleanAttribute }) requiredTrue?: boolean;
  @Input({ transform: booleanAttribute }) email?: boolean;
  @Input() minLength?: number;
  @Input() maxLength?: number;

  private injector = inject(Injector);

  protected value: InputTextareaValueType;
  protected inputId: string;
  protected isDisabled: boolean;

  private onChange: (value: InputTextareaValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  @HostBinding('class') get classNames() {
    return classnames('ui-input', `ui-input-textarea`, {
      'ui-input--with-helper': this.helper,
      'ui-input--with-errors': !this.isValid,
    });
  }

  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }

  get isRequired() {
    return this.formControl?.hasValidator(Validators.required) || this.required === true;
  }

  get isValid() {
    return this.formControl?.valid;
  }

  get isTouched() {
    return this.formControl?.touched;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }

  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }

  get valueLength(): number {
    return this.value?.length || 0;
  }

  ngOnInit(): void {
    this.inputId = `input-textarea-${++INPUT_UID}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: string) {
    this.value = value || null;
  }
  registerOnChange(onChange: (value: InputTextareaValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  onKeyUp($event: KeyboardEvent) {
    const newValue = (<HTMLInputElement>$event.target).value;

    this.writeValue(newValue);
    this.onChange(newValue);
    this.onValidatorChange();
    this.onTouched();
  }

  focus() {
    this.input.nativeElement.focus();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getErrorMessage([key, data]: [string, any]) {
    return this.messages?.[key] || this.formReactiveConfigService.getErrorMessage(key, data);
  }
}

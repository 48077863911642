import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppPathLocationStrategy } from './app.path-location-strategy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, RouterOutlet],
})
export class AppComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private pathLocation = inject(AppPathLocationStrategy);

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (document.readyState === 'loading') {
        // Loading hasn't finished yet
        document.addEventListener('DOMContentLoaded', () => this.handleDeferUiInteraction());
      } else {
        // `DOMContentLoaded` has already fired
        this.handleDeferUiInteraction();
      }
    }
  }

  private handleDeferUiInteraction() {
    window['APP_IS_READY'] = true;

    if (window['APP_LAST_CLICKED_ELEMENT']) {
      if (window['APP_LAST_CLICKED_ELEMENT'].tagName === 'A') {
        const anchorUrl = window['APP_LAST_CLICKED_ELEMENT'].getAttribute('href');
        const anchorUrlWithoutSlug = anchorUrl.replace(this.pathLocation.getBaseHref(), '');

        this.router.navigate(['/' + anchorUrlWithoutSlug]);
      } else {
        window['APP_LAST_CLICKED_ELEMENT'].click();
      }

      setTimeout(() => {
        document.querySelector('#appPrebootOverlay').remove();
      }, 500);
    }
    document.body.removeEventListener('click', window['APP_CLICK_CAPTURE_LISTENER']);
  }
}

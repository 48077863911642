import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { NgClass, NgStyle, NgTemplateOutlet, isPlatformServer } from '@angular/common';
import { ScrollPositionService, ZonelessEventDirective } from '@pedix-workspace/angular-utils';
import {
  VirtualListItemComponent,
  VirtualListWrapperComponent,
} from '@pedix-workspace/angular-ui-virtual-list';
import {
  DetectScrollDirective,
  ScrollBreakItemDirective,
  ScrollBreaksWrapperDirective,
} from '@pedix-workspace/angular-ui-sticky-heading';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import {
  HorizontalSliderComponent,
  HorizontalSliderItemComponent,
} from '@pedix-workspace/angular-ui-horizontal-slider';
import classnames from 'classnames';
import { ProductGroupingStrategy } from '@pedix-workspace/utils';

export type ItemListGroup<G extends { id: string; name: string }, I> = {
  group: G;
  indexedItems: {
    listIndex: number;
    item: I;
  }[];
};

@Component({
  selector: 'pxw-item-list',
  standalone: true,
  templateUrl: './item-list.component.html',
  styleUrl: './item-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    ZonelessEventDirective,
    VirtualListWrapperComponent,
    VirtualListItemComponent,
    ButtonComponent,
    HorizontalSliderComponent,
    HorizontalSliderItemComponent,
    ScrollBreaksWrapperDirective,
    ScrollBreakItemDirective,
    DetectScrollDirective,
  ],
})
export class ItemListComponent<
  G extends { id: string; name: string },
  I extends { id: string; name: string },
> implements AfterViewInit
{
  @ContentChild('layoutTemplate') layoutTemplate: TemplateRef<unknown>;
  @ContentChild('groupHeadingTemplate') groupHeadingTemplate: TemplateRef<{
    group: G;
    groupItem: ItemListGroup<G, I>;
  }>;
  @ContentChild('listItemTemplate') listItemTemplate: TemplateRef<{
    group: G;
    item: I;
    index: number;
  }>;

  @ViewChild('scrollTarget') scrollTarget: ElementRef<HTMLElement>;
  @ViewChild(HorizontalSliderComponent) slider: HorizontalSliderComponent;
  @ViewChild(ScrollBreaksWrapperDirective) scrollBreaksWrapper: ScrollBreaksWrapperDirective;

  private scrollPosition = inject(ScrollPositionService);
  private platformId = inject(PLATFORM_ID);

  @Input({ required: true }) itemListGroups: ItemListGroup<G, I>[] = [];
  @Input({ required: true }) emptyListText: ItemListGroup<G, I>[] = [];
  @Input({ required: true }) itemHeight: number;
  @Input({ required: true }) rememberScrollSettings: { key: string; context?: string } | null;
  @Input({ required: true }) groupingStrategy: ProductGroupingStrategy;
  @Input() initialGroupId: string;
  @Input() autoHideSlider = false;

  @Input() initialRenderItemsQty = 25;

  @Output() selectedGroupIdChange = new EventEmitter<string>();

  @HostBinding('class')
  get classNames(): string {
    return classnames({
      'without-headings': !this.displayHeadings,
    });
  }

  scrollTargetHasScrollbar = false;
  isFirstRender = true;
  skipNextBreakItemChange = false;

  protected _selectedGroupId: string | undefined;

  get displayHorizontalSlider() {
    if (this.groupingStrategy === 'none') {
      return false;
    }
    if (this.autoHideSlider) {
      return this.scrollTargetHasScrollbar && this.itemListGroups.length > 1;
    }
    return true;
  }

  get displayHeadings() {
    if (this.groupingStrategy === 'none' || this.itemListGroups[0]?.group.id === 'none') {
      return false;
    }
    return true;
  }

  ngAfterViewInit(): void {
    if (
      this.scrollTarget?.nativeElement &&
      this.rememberScrollSettings &&
      this.scrollPosition.get(this.rememberScrollSettings.key, this.rememberScrollSettings.context)
    ) {
      this.scrollTarget.nativeElement.scrollTop = this.scrollPosition.get(
        this.rememberScrollSettings.key,
        this.rememberScrollSettings.context,
      );
    }
    if (this.initialGroupId) {
      this.skipNextBreakItemChange = true;

      this.setActiveGroup(this.initialGroupId, false, { scrollSlider: true, scrollTarget: true });
    }
    this.isFirstRender = false;
  }

  onProductListScroll($event: Event) {
    if ($event.target && this.rememberScrollSettings) {
      this.scrollPosition.set(
        this.rememberScrollSettings.key,
        (<HTMLElement>$event.target)['scrollTop'],
        this.rememberScrollSettings.context,
      );
    }
  }

  setActiveGroup(
    groupId: string | undefined,
    animated: boolean,
    options: { scrollTarget?: boolean; scrollSlider?: boolean } = {},
  ) {
    if (this._selectedGroupId === groupId) {
      return;
    }
    this._selectedGroupId = groupId;

    if (isPlatformServer(this.platformId) || !groupId) {
      return;
    }

    if (options.scrollTarget && this.scrollBreaksWrapper) {
      this.scrollBreaksWrapper.scrollToItem(groupId, animated);
    }
    if (options.scrollSlider && this.slider) {
      this.slider.scrollToId(groupId, animated);
    }
    this.selectedGroupIdChange.emit(groupId);
  }

  onBreakItemActiveChange(item: ScrollBreakItemDirective) {
    if (this.skipNextBreakItemChange) {
      this.skipNextBreakItemChange = false;

      return;
    }
    this.setActiveGroup(item.id, true, {
      scrollSlider: true,
    });
  }

  onScrollStateChange(hasScroll: boolean) {
    this.scrollTargetHasScrollbar = hasScroll;
  }

  onSliderItemClick(categoryId: string) {
    this.setActiveGroup(categoryId, true, {
      scrollTarget: true,
      scrollSlider: true,
    });
  }
}
